.pos-r {
    position: relative !important;
}

.old-img {
    height: 140px !important;
}

.img-card {
    height: 390px;
    width: 287px;
    overflow: hidden;
}

.multi-img {
    overflow: hidden;
}

.action {
    z-index: 9;
    margin: -3.73rem 0 0 0;
}

.delete-img {
    /* position: fixed !important; */
    width: 3.5rem;
    left: 0px;
    padding: 5px 10px;
    z-index: 9;
    border-bottom-right-radius: 50%;
    background-color: #fff5f8;
    color: var(--light);
    font-size: 19px;
    cursor: pointer;
    transition: all .7s !important;
}

.delete-img:hover {
    background-color: #f1416c;
}

.del-icon {
    color: #f1416c !important;
    transition: all .3s !important;
    font-size: 21px !important;
    margin: 3px 0 0 0;
    position: absolute;
}

.delete-img:hover .del-icon {
    color: #fff !important;
}

.img-parent {
    height: 91%;
}

.update-img {
    width: 3.5rem;
    right: 0px;
    padding: 5px 10px;
    z-index: 9;
    border-bottom-left-radius: 50%;
    background-color: #f1faff;
    color: var(--light);
    font-size: 19px;
    cursor: pointer;
    transition: all .7s !important;
}

.update-img:hover {
    background-color: #009ef7;
}

.update-icon {
    color: #009ef7 !important;
    transition: all .3s !important;
    font-size: 20px !important;
    margin: 3px 0 0 4px;
}

.update-img:hover .update-icon {
    color: #fff !important;
}

.hr-tag {
    opacity: .05 !important;
}


.ql-container {
    min-height: 13rem !important;
}

.email-count {
    font-size: 15px !important;
    height: 23px !important;
    margin: -3px 0 0 31px !important;
    /* padding: 0px 0px 0px 3px !important; */
    width: 23px !important;
}

.email-icon {
    color: var(--bs-primary) !important;
    font-size: 30px !important;
    margin: 0 3rem 0 0 !important;
    transition: all .6s;
}

.email-icon:hover {
    color: var(--bs-info) !important;
}

.order-adresse-modal.modal-dialog {
    max-width: 850px !important;
  }


  .settings-icon{
    transform: scale(2) !important;
  }
  .settings-icon:hover{
    transform: scale(2.2) !important;
    color: var(--kt-text-danger) !important;
  }