.custom-icon {
    font-size: 30px !important;
    background-color: var(--kt-text-primary) !important;
    color: var(--kt-text-light) !important;
    border-radius: 4px !important;
    padding: 3px !important;
}

.card-text{
    margin: 0px 1px 0px 3rem !important;
    font-size: 20px !important;
  }